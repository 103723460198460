<template>
  <div class="message">
    <p class="message__warning" v-if="isSlopeDisclaimer">
      {{ slopeDisclaimer }}
    </p>
    <p class="message__results" v-if="isCommonNoteResults">
      {{ commonText }}
    </p>
    <p class="message__sector" v-if="isSectorTableNote">
      {{ commonText }}
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Message',
  props: {
    type: String
  },
  data: function () {
    return {
      isSlopeDisclaimer: this.type === 'slopeDisclaimer',
      isCommonNoteResults: this.type === 'commonNote',
      isSectorTableNote: this.type === 'sectorNote'
    }
  },
  computed: {
    ...mapState({
      disclaimer: state => state.result.disclaimer,
      slopeDisclaimer: state => state.slopeDisclaimer
    }),
    commonText() {
      return this.disclaimer || this.$t('message.info-messages.results')
    }
  }
}
</script>

<style scoped lang="sass">
.message
  p
    font-family: $main-font
  &__warning
    margin: rem(20) rem(0) rem(10)
    color: $red
    +media((font-size: (320: rem(10), 600: rem(13))))
  &__results
    color: $dark-grey
    font-weight: 500
    +media((font-size: (320: rem(10), 650: rem(14))))
    +media((width: (320: 100%, 650: 92%, 960: 100%)))
    +media((margin: (320: rem(12) rem(10) rem(10), 650: rem(16) auto 0, 960: rem(16) 0 0)))
  &__sector
    margin: 0
    background: $white
    +media((padding: (320: rem(16) rem(16) rem(8), 960: rem(24) rem(18) rem(16))))
    +media((font-size: (320: rem(10), 650: rem(12))))
</style>
